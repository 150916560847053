// PROPERTY KEYS
export const PropertyKeys = {
  countryIsoCode: "country_iso_code",
  currencyCode: "currency_code",
  dropdownSearchSelected: "dropdown_search_selected",
  jobCategoryId: "job_category_id",
  jobCategory: "job_category",
  jobDraftId: "job_draft_id",
  isAccountAutocreated: "is_account_autocreated",
  isExpandedPills: "is_expanded_pills",
  isFirstTime: "is_first_time",
  jobId: "job_id",
  locale: "locale",
  marketplace: "marketplace",
  metro: "metro",
  metroId: "metro_id",
  prepaidAmount: "prepaid_amount",
  price: "price",
  searchString: "search_string",
  tip: "tip",
  taskLabel: "task_label",
  taskTemplateId: "task_template_id",
  triggerLocation: "trigger_location",
  triggerMethod: "trigger_method",
  searchQuery: "search_query",
  funnelId: "funnel_id",
  userGuid : "user_guid",
  path: "path",
  cartItems: "cart_items",
  articleNumber: "article_number",
  quantity: "quantity",
};
