export const experiments = {
  QUICKFORM_OPTIMIZATION_STEP_ONE: {
    key: "growth-2021-01-22-quickform_optimization_step_one",
    variants: { STEP1: "form_optimization" },
  },
  QUICKFORM_OPTIMIZATION_STEP_TWO: {
    key: "growth-2021-04-20-quickform_optimization_step_two",
    variants: { STEP2: "form_optimization_step_two" },
  },
  MWEB_REMOVE_TASKER_PROFILE_CTA: {
    key: "booking-2021-12-22-mweb_remove_tasker_profile_cta",
    variants: { CTA_OPTIMIZATION: "profile_cta_optimization" },
  },
  SPE_ENABLED: {
    key: "payments-2025-02-11-spe_paypal_ikea_eu",
    variants: { ENABLED: "spe_enabled" },
  },
};
